/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // init Foundation JavaScript
        $(document).foundation(); 

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

        // set default Sticky size for Foundation Sticky
        Foundation.Sticky.defaults.stickyOn = 'large';

        // Mobile Menu
        Foundation.DropdownMenu.defaults.closeOnClick = true;

        // change Menu class on scroll
        $(window).scroll(function() {
          if ($(this).scrollTop() > 50) {
            $('body').addClass('solid-header');
          } else {
            $('body').removeClass('solid-header');
          }
        });

        // add no-touch class for desktop browsers
        var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
        if (!touchsupport) { 
            document.documentElement.className += " no-touch ";
        }

        // Toggle menu
        $('[data-off-canvas]').on("opened.zf.offcanvas", function(e) {
          $('#toggle-menu').addClass('open');
          $('#main-header').addClass('toggle-menu-open');
          $('body').addClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').addClass('active');
        });
        $('[data-off-canvas]').on("closed.zf.offcanvas", function(e) {
          $('#toggle-menu').removeClass('open');
          $('#main-header').removeClass('toggle-menu-open');
          $('body').removeClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').removeClass('active');          
        });

        $('#toggle-menu .menu-item-has-children > a').click(function(){
           var i = 0;
           var parent = $(this).parent();
           $('#toggle-menu ul > li').each(function(){
             if($('#toggle-menu ul > li').index(parent)!==i){
               $(this).removeClass('open');
               $(this).find('*').removeClass('open');
             }
             i++;
           });
           $(this).parent().toggleClass('open');
           $(this).toggleClass('open');
           $(this).siblings('.foldable-content').toggleClass('open');
           return false;
        });     

        // Smooth scrolling
        if(window.location.hash) {
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $('header').height() + 'px'
          }, 1000);
        }         

        // Scrolldown Button scrolling
        $('.scrolldown-button').click(function(){
          var nextSection = $(this).closest('section').next();

          $('html, body').animate({
              scrollTop: nextSection.offset().top
          }, 1000);            
        });
  
        // hover siblings
        $(".sibling-hover").hover(function() {
            $(this).siblings(".sibling-hover").addClass("sibling-hovered");
        }, function() {
            $(this).siblings(".sibling-hover").removeClass("sibling-hovered");
        });

        // Search
        function initSearch() {
          $('.top-bar-right .search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $('body').addClass('search-bar-open');
            $('.navbar-nav').addClass('search-bar-active');
            $('.top-bar-right .search-form .search-input').focus();
            $('.top-bar-right .search-form .search-input').keypress(function (e) {
              if (e.which === 13) {
                $(this).parents('form').submit();
                return false;
              }
            });
          });
          $('.top-bar-right .search-form .search-close').on('click', function(e) {
            e.preventDefault();
            $('.top-bar-right .search-input').blur();
            $('body').removeClass('search-bar-open');
            $('.navbar-nav').removeClass('search-bar-active');
          });
          $('.toggle-menu-overlay').on('click', function(e) {
            e.preventDefault();
            $('.top-bar-right .search-form .search-input').blur();
            $('body').removeClass('search-bar-open');
            $('.navbar-nav').removeClass('search-bar-active');
          });  

          $('.search-section .search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $('.search-section .search-input').focus();
            $('.search-section .search-input').keypress(function (e) {
              if (e.which === 13) {
                $(this).parents('form').submit();
                return false;
              }
            });
          }); 

          $('.search-toggle-menu .search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $(this).parents('form').submit();
            return false;
          });           
          $('.search-toggle-menu .search-input').keypress(function (e) {
            if (e.which === 13) {
              $(this).parents('form').submit();
              return false;
            }
          });                           
        }

        initSearch();             

        // Sliders
        function renderCounter(slick,carouselId) {
          var slidesToShow;
          if(slick.activeBreakpoint!==null){
            slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
          } else {
            slidesToShow = slick.options.slidesToShow;
          }

          if($('#'+carouselId).parents('.slider-wrapper').find('.slick-next').length>0){
            $('#'+carouselId).parents('.slider-wrapper').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
            $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').show();
            $('#'+carouselId).parents('.slider-wrapper').find('.slick-dots').show();
          } else {
            $('#'+carouselId).parents('.slider-wrapper').find('.counter').html('');
            $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').hide();
            $('#'+carouselId).parents('.slider-wrapper').find('.slick-dots').hide();
          }
        }        
        
        $('.common-slider').each(function (idx, item) {

          if (!$(this).hasClass('slick-initialized')) {

            var carouselId = "carousel_" + idx;
            this.id = carouselId;
            $(this).attr('data-counter','carousel_slick-control_' + idx);
            $(this).next('.slider-control').attr('id','carousel_slick-control_' + idx);
            
            $(this).on('init', function(event, slick) {
              //console.log('init');
            });

            $(this).on('setPosition', function(event, slick) {
              //console.log('setPosition');
              renderCounter(slick,carouselId);
            });

            $(this).on('afterChange', function(event, slick, currentSlide){
              //console.log('afterChange');
            });            

            $(this).slick({
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 3,
              appendArrows: $(this).next('.slider-control'),
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2      
                  }
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
            });

          }

        }); 
       
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired  

        //$('[data-sticky]').on("sticky.zf.stuckto:top", function(e) {
        //  console.log('stuckto:top');
        //});        
        //$('[data-sticky]').on("sticky.zf.stuckto:bottom", function(e) {
        //  console.log('stuckto:bottom');
        //});         
        //$('[data-sticky]').on("sticky.zf.unstuckfrom:top", function(e) {
        //  console.log('unstuckfrom:top');
        //});        
        //$('[data-sticky]').on("sticky.zf.unstuckfrom:bottom", function(e) {
        //  console.log('unstuckfrom:bottom');
        //});
     
       
      },
      resize: function() {

        var windowHeight = $(window).height() - $('header').height();
        var fullWinHeight = $(window).height() - $('#main-header').height() - $("#footer-wrap").height();
        var headerHeight = $('#main-header').outerHeight(true);
        var footerHeight = $("#footer-wrap").outerHeight(true);

        // square grid items
        function squareGridItems(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).width();
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }                     
          });
          group.height(tallest);          
        }          

        if (Foundation.MediaQuery.atLeast('medium')) {
          squareGridItems($(".question-archive-section .category-cell"));
        } 
          squareGridItems($(".related-section .category-link"));

        // Sync heights
        function equalHeight(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).height();
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          group.height(tallest);      
        }

        // Sync heights on elements on medium and larger screens
        if (Foundation.MediaQuery.atLeast('medium')) {
          equalHeight($(".three-teaser-text-wrap"));
        } else {
          $(".three-teaser-text-wrap").css({'height': 'auto'});         
        }    

        // Prevent small screen page refresh sticky bug
        $(window).on('sticky.zf.unstuckfrom:top', function(e) {
          if (!Foundation.MediaQuery.atLeast('xlarge')) {
            $(e.target).removeClass('is-anchored is-at-top').attr('style', '');
            //console.log('unstuckfrom:top');
          }
        });         
        $(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
          if (!Foundation.MediaQuery.atLeast('xlarge')) {
            $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
            //console.log('unstuckfrom:bottom');
          }
        });        

        // position search box on location-map-section
        if (Foundation.MediaQuery.atLeast('large')) {
          var lmsWidth = $('#top-menu').innerWidth();
          //var lmsMinHeight = $('.search-box').outerHeight();          
          $(".location-map-section .section-inner").css({'width': lmsWidth});
          //$(".location-map-section .section-inner").css({'min-height': lmsMinHeight + 85 + "px"});  
        } else {
          $(".location-map-section .section-inner").css({'width': 'auto'});
          //$(".location-map-section .section-inner").css({'min-height': 'auto'});          
        } 

        // Sticky footer
        function stickyFooter() {
          if (Foundation.MediaQuery.atLeast('large')) {
            var combinedHeight = windowHeight - footerHeight;
            $("main.main").css('min-height', combinedHeight + "px");
          } else {
            $("main.main").css('min-height', "100vh");          
          }                    
        }

        if ($( "#footer-wrap" ).length) {
          stickyFooter();
        }       

      },
      windowload: function() {
        //console.log('loaded');
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {

      },
      windowload: function() {

      }
    },
    'page_template_template_sidebar': {
      init: function() {

        // fixed side Menu class on scroll
        $(window).scroll(function() {
          if ($(this).scrollTop() > 85) {
            if (Foundation.MediaQuery.atLeast('large')) {
              $('.side-menu').addClass('fixed');

              if ($('.sub-level-teaser-section').length) {
                if($('.side-menu').offset().top + $('.side-menu').height() >= ($('.sub-level-teaser-section').offset().top - 50)) {
                  $('.side-menu').removeClass('fixed').addClass('absolute');
                }                             
              } else {
                if ($('.find-center-teaser-section').length) {
                  if($('.side-menu').offset().top + $('.side-menu').height() >= ($('.find-center-teaser-section').offset().top - 50)) {
                    $('.side-menu').removeClass('fixed').addClass('absolute');
                  }               
                }                
              }

              if( ($(document).scrollTop() + $('.side-menu').height() + 115) < ($('.sidebar-template-section').height() + $('header').height()) ) {
                $('.side-menu').removeClass('absolute').addClass('fixed');
              } 

            } else {
              $('.side-menu').removeClass('fixed');          
            }                 
          } else {
            $('.side-menu').removeClass('fixed');
          }
        });

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resizeEvents);
  $(window).on("load",UTIL.resizeEvents);
  $(window).on("load",UTIL.windowLoadEvents);


})(jQuery); // Fully reference jQuery after this point.

